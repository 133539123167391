/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
#footer-wrap {
  background-color: #f8f9fa;
}
.footer {
  padding-top: 30px;
  padding-bottom: 15px;
  width: 100%;
  color: #6a6a6a;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #6a6a6a;
  }
  img {
    display: inline-block;
  }
}
.fw-strong {
  font-weight: 600;
}
.border-light {
  border-color: #efefef !important;
}
/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
  z-index: 999 !important;
}
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0;
    width: 85%;
    max-width: 525px;
    img {
      display: inline-block;
      margin-right: 7px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 50px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: $theme-primary;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: 'Roboto Slab', var(--font-family);
      line-height: 1.2;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      letter-spacing: 0.03rem;
      font-family: var(--font-family);
      font-weight: 600;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: 400;
      font-size: 15px;
      color: #333;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover:before {
        color: $theme-primary;
      }
    }
  }
}

#banner-wrap {
  .homepage-banner {
    width: 300px;
  }
  .details {
    ul,
    p {
      font-size: 1.1em;
    }
  }
}
#steps-wrap {
  .icon-border {
    width: 60px;
    height: 60px;
    border: 1px solid $headline-secondary;
    border-radius: 50%;
  }
  .icon {
    width: 35px;
    height: auto;
    vertical-align: middle;
    fill: $headline-secondary;
  }
}
#cta-wrap,
.gradient-wrap {
  background: $theme-primary;
  background: linear-gradient(30deg, #2e397c, #3f4a8e 42%, #8396cd);
}
#cta-wrap {
  .dropdown-list {
    width: 95%;
    max-width: 300px;
  }
}
.card {
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 5px, rgba(0, 0, 0, 0.24) 0 1px 4px;
  border: 1px solid $theme-primary;
  border-top: 6px solid $theme-primary;
  .card-header {
    background: #fff;
    border-radius: 0.8rem 0.8rem 0 0;
  }
  .card-footer {
    border-radius: 0 0 0.8rem 0.8rem;
  }
}
#banner-wrap {
  &.ten-hour {
    background: transparent url(../images/banners/10-hour-banner-mobile.jpg) no-repeat 50% 50% / cover;
  }
  &.four-hour {
    background: transparent url(../images/banners/4-hour-banner-mobile.jpg) no-repeat 50% 50% / cover;
  }
  .card {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
  &.home {
    background: linear-gradient(30deg, #4b58a5, #4b58a5 42%, #8396cd);
    h1,
    h2,
    .price {
      color: #fff;
    }
    ul {
      color: #fff;
      li:before {
        color: #fff;
      }
    }
    #banner {
      h1 {
        line-height: 1.2em;
      }
    }
  }
}
#sideNav {
  span.big,
  span.small {
    display: inline;
    font-size: 1.5em;
    font-weight: 500;
    color: $theme-primary;
    line-height: 1em;
    vertical-align: middle;
  }
  span.big {
    font-weight: 600;
    font-family: 'Roboto Slab', var(--font-family);
    line-height: 1.2;
  }
  span.small {
    display: none;
    margin-top: 0;
    font-size: 16px;
    letter-spacing: 0.03rem;
    font-family: var(--font-family);
    font-weight: 600;
  }
}
.dropdown-list .dropdown {
  transition: 0.2s;
  &.is-active {
    background: $headline-secondary;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
.bg-purple {
  background: #fafaff;
  &.wrap {
    border-top: 1px solid #e5e7ff;
    border-bottom: 1px solid #e5e7ff;
  }
  &.full-border {
    border: 1px solid #e5e7ff;
  }
}
.browse-courses {
  img {
    max-width: 75px;
  }
  a:hover > div {
    transition: 0.3s;
    background: darken(#eef0fd, 5%);
  }
}
img.asc {
  max-width: 100px;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #steps-wrap {
    .icon-border {
      width: 60px;
      height: 60px;
    }
    .icon {
      width: 30px;
      height: auto;
    }
  }
  #header a.logo {
    span.small {
      display: inline-block;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      font-size: 18px;
    }
  }
  #banner {
    .homepage-banner {
      width: 500px;
    }
  }
  #steps-wrap {
    .icon-border {
      width: 80px;
      height: 80px;
      border-width: 2px;
    }
    .icon {
      width: 40px;
      height: auto;
    }
  }
  #banner-wrap {
    &.ten-hour {
      background: transparent url(../images/banners/10-hour-banner.jpg) no-repeat 0 0 / cover;
    }
    &.four-hour {
      background: transparent url(../images/banners/4-hour-banner.jpg) no-repeat 0 0 / cover;
    }
    &.home {
      background: #fff;
      h1 {
        color: $theme-primary;
        span {
          color: $headline-secondary;
        }
      }
      ul {
        color: inherit;
        li:before {
          color: $headline-secondary;
        }
      }
      .price {
        color: inherit;
      }
    }
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #steps-wrap {
    .icon-border {
      width: 100px;
      height: 100px;
      border-width: 3px;
    }
    .icon {
      width: 48px;
      height: auto;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 68.5em) {
  #nav-wrap {
    border-bottom: 0;
    .login {
      a {
        background: $headline-secondary;
        border-radius: 50px;
        padding: 5px 15px;
        &:hover {
          background: darken($headline-secondary, 5%);
        }
      }
    }
  }
}
